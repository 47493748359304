<template>
  <div>
    <pwd-contair>
      <div slot="menu">
        <div
          class="addBtn bd-orange wid100 het25 orange F12"
          @click="addLocation()"
        >
          新增收货地址
        </div>
      </div>
      <div slot="pwdContair">
        <div class="Pbm40">
          <add-arr
            v-for="item in addressList"
            :key="item.id"
            :addressInfo="item"
            class="Mbm15"
            :regionSelect="regionSelect"
            @setDefaultAddr="setDefaultAddr"
            @deleteUserAddress="deleteUserAddress"
            @editInfo="editInfo"
          ></add-arr>
        </div>
      </div>
    </pwd-contair>
    <!-- 新增地址弹窗 -->
    <el-dialog
      :title="addrForm.id ? '修改' : '新增' + '收货地址'"
      :visible.sync="addLocationDialog"
      width="600"
      :before-close="handleClose"
    >
      <div>
        <el-form
          :model="addrForm"
          :rules="addrRules"
          ref="addrForm"
          label-width="100px"
          class="addrForm"
        >
          <el-form-item label="收货人" prop="consigneeName">
            <el-input v-model="addrForm.consigneeName"></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="area">
            <el-cascader
              v-model="addrForm.area"
              :options="regionSelect"
              :props="{
                value: 'key',
                label: 'title',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="detailAddress">
            <el-input v-model="addrForm.detailAddress"></el-input>
          </el-form-item>
          <el-form-item label="邮编" prop="postalCode">
            <el-input v-model="addrForm.postalCode"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile">
            <el-input v-model="addrForm.mobile"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('addrForm')"
              >保存收货地址</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AddArr from "@/components/purchaser/addArr.vue";
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";

import {
  findUserAddressList,
  insertOrUpdateUserAddress,
  deleteUserAddress,
} from "@/api/user";

import { mapGetters } from "vuex";

export default {
  components: { pwdContair, AddArr },
  data() {
    return {
      areaOptions: [],
      addressList: [],
      addLocationDialog: false,
      addrForm: {
        consigneeName: "",
        area: [],
        detailAddress: "",
        mobile: "",
        postalCode: "",
      },
      addrRules: {
        consigneeName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请选择所在地区省市县", trigger: "blur" },
        ],
        detailAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        postalCode: [
          { required: true, message: "请输入邮编", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.findUserAddressList(this.userInfo.id);
    // this.$store.dispatch("getRegionSelect");
  },
  computed: {
    ...mapGetters(["userInfo", "regionSelect"]),
  },
  methods: {
    // 获取收货地址
    findUserAddressList(userId) {
      if (!userId) {
        return false;
      }
      findUserAddressList(userId).then((res) => {
        this.$set(this, "addressList", res.data);
      });
    },
    addLocation() {
      this.addrForm = {};
      this.addLocationDialog = true;
    },
    handleClose() {
      this.$refs["addrForm"].resetFields();
      this.addLocationDialog = false;
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = Object.assign({ userId: this.userInfo.id }, this.addrForm);
          data.provinceCode = data.area[0];
          data.cityCode = data.area[1];
          data.districtCode = data.area[2];
          this.insertOrUpdateUserAddress(data);
        }
      });
    },
    // 新增、修改地址信息请求
    insertOrUpdateUserAddress(data) {
      insertOrUpdateUserAddress(data).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.findUserAddressList(this.userInfo.id);
          this.addLocationDialog = false;
          this.$refs["addrForm"].resetFields();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 设置为默认地址
    setDefaultAddr(data) {
      data.defaultAddress = "Y";
      this.insertOrUpdateUserAddress(data);
    },
    // 删除用户地址
    deleteUserAddress(id) {
      deleteUserAddress(id).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.findUserAddressList(this.userInfo.id);
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 编辑
    editInfo(data) {
      this.addLocationDialog = true;
      data.area = [data.provinceCode, data.cityCode, data.districtCode];
      this.addrForm = Object.assign({}, data);
    },
  },
};
</script>
<style lang="scss" scoped>
.addBtn {
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}
.addrForm {
  padding: 0px 70px 50px 50px;
  .el-button {
    margin-left: 115px;
    padding: 12px 30px;
  }
}
.el-dialog__header {
  background-color: #f4f4f4;
}
</style>
