<template>
  <div class="arrContair">
    <div class="arr_head het40 Plf20 Prt20 bg_f4f4">
      <div>
        <!-- <span class="Mrt30 F14">yeah</span> -->
        <el-button
          class="arrBtn"
          type="primary"
          v-if="addressInfo.defaultAddress == 'Y'"
          >默认地址</el-button
        >
        <el-button class="arrBtn" @click="setDefaultAddr()" v-else
          >设为默认地址</el-button
        >
      </div>
      <div class="close co_f00" @click="deleteUserAddress()">
        <div>删除地址</div>
      </div>
    </div>
    <div class="arr_bottom Plf20 Prt20 Ptp5">
      <div class="title">
        <span>收货人：</span>
        <span>所在地区：</span>
        <span>详细地址：</span>
        <span>手机：</span>
        <span>邮编：</span>
      </div>
      <diV class="message">
        <span>{{ addressInfo.consigneeName }}</span>
        <span>{{ areaName }}</span>
        <span>{{ addressInfo.detailAddress }}</span>
        <span>{{ addressInfo.mobile }}</span>
        <span>{{ addressInfo.postalCode }}</span>
      </diV>
      <div class="editor co_09f">
        <span class="F14" @click="editInfo">编辑</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    addressInfo: {
      type: Object,
      default: () => {
        return {
          consigneeName: "",
          area: [],
          detailAddress: "",
          mobile: "",
          postalCode: "",
        };
      },
    },
    regionSelect: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    areaName() {
      let name = "";
      let data = this.addressInfo;
      let provinceCode = data.provinceCode;
      let cityCode = data.cityCode;
      let districtCode = data.districtCode;
      let regionSelect = this.regionSelect;

      for (let x = 0; x < regionSelect.length; x++) {
        let _x = regionSelect[x];
        if (provinceCode == _x.key) {
          name = _x.title;
          if (_x.children) {
            for (let n = 0; n < _x.children.length; n++) {
              let _n = _x.children[n];
              if (cityCode == _n.key) {
                name = name + " " + _n.title;
                if (_n.children) {
                  for (let m = 0; m < _n.children.length; m++) {
                    let _m = _n.children[m];
                    if (districtCode == _m.key) {
                      name = name + " " + _m.title;
                    }
                  }
                }
              }
            }
          }
        }
      }

      return name;
    },
  },
  methods: {
    deleteUserAddress() {
      this.$emit("deleteUserAddress", this.addressInfo.id);
    },
    setDefaultAddr() {
      this.$emit("setDefaultAddr", this.addressInfo);
    },
    editInfo() {
      this.$emit("editInfo", this.addressInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
.arrContair {
  border-left: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  .arr_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      padding: 0px 5px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .arr_bottom {
    padding-bottom: 15px;
    .title {
      display: inline-block;
      padding-right: 5px;
      span {
        padding: 10px 0px;
        font-size: 14px;
        color: #747474;
        display: block;
      }
    }
    .message {
      display: inline-block;
      span {
        padding: 10px 0px;
        font-size: 14px;
        display: block;
      }
    }
    .editor {
      float: right;
      margin-top: 170px;
      cursor: pointer;
    }
  }
}
</style>
